import React from 'react';
import ReactDOM from 'react-dom';
import { FlagsProvider } from '@nm-utils-lib-web/flags';
import * as analytics from '@nm-utils-lib-web/analytics';
import { apps } from '@nm-utils-lib-web/monitoring';
import './helpers/i18n';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './components/App';

const app = apps.NM_MAINTENANCE_WEB;

analytics.init(app, { groupId: window.NutmegConfig.ORGANISATION });

ReactDOM.render(
  <FlagsProvider>
    <Router>
      <App />
    </Router>
  </FlagsProvider>,
  document.getElementById('root')
);
