import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { withRouter } from 'react-router-dom';
import { shape, string } from 'prop-types';
import { useFlag, Flags } from '@nm-utils-lib-web/flags';

import AppContainer from '../AppContainer';
import MaintenanceMessage from '../MaintenanceMessage';
import { handleReturnTo } from '../../helpers/handleReturnTo';
import { startTimerToRefreshPage } from '../../utils/refreshPage';

const App = ({ location }) => {
  const { t } = useTranslation();
  const isMaintenanceModeFlagEnabled = useFlag(Flags.MAINTENANCE_MODE);
  const queryString = location.search;

  handleReturnTo(isMaintenanceModeFlagEnabled, queryString);
  startTimerToRefreshPage();

  return (
    <>
      <Helmet>
        <title>{t('app.common.appTitle')}</title>
      </Helmet>
      <AppContainer>
        <MaintenanceMessage />
      </AppContainer>
    </>
  );
};

App.propTypes = {
  location: shape({
    search: string.isRequired
  })
};

App.defaultProps = {
  location: {
    search: ''
  }
};

export default withRouter(App);
