import React from 'react';
import { Text, Heading, textAligns } from '@nutkit/component-text';
import Icon, { iconSizes, iconColors, IconCog } from '@nutkit/component-icon';
import Link from '@nutkit/component-link';
import Align, { justifyContentRules, alignItemsRules, directionRules } from '@nutkit/component-align';
import { Trans, useTranslation } from '@nm-utils-lib-web/translations';

const MaintenanceMessage = () => {
  const { t } = useTranslation();
  const TRANSLATION_NAMESPACE = 'app.maintenance';

  return (
    <div>
      <Align
        justifyContent={justifyContentRules.CENTER}
        alignItems={alignItemsRules.CENTER}
        direction={directionRules.COLUMN}
        fullHeight
      >
        <Align justifyContent={justifyContentRules.CENTER}>
          <Icon component={IconCog} size={iconSizes.XXXL} color={iconColors.GREY} stackSpacing spin />
        </Align>
        <Heading textAlign={textAligns.CENTER}>{t(`${TRANSLATION_NAMESPACE}.title`)}</Heading>
        <Text textAlign={textAligns.CENTER}>{t(`${TRANSLATION_NAMESPACE}.description`)}</Text>
        <Text textAlign={textAligns.CENTER} noStack>
          <Trans
            i18nKey={`${TRANSLATION_NAMESPACE}.contact.text`}
            components={[
              <Link
                href={`mailto:${t(`${TRANSLATION_NAMESPACE}.contact.supportEmail`)}`}
                aria-label={t(`${TRANSLATION_NAMESPACE}.contact.ariaLabel`)}
              >
                Link text
              </Link>
            ]}
          />
        </Text>
      </Align>
    </div>
  );
};

export default MaintenanceMessage;
