import { isValidURL } from '@nm-utils-lib-web/routes/utils/urlUtils';

export const handleReturnTo = (isMaintenanceModeFlagEnabled, searchParams) => {
  if (!isMaintenanceModeFlagEnabled) {
    const returnToUrl = new URLSearchParams(searchParams).get('returnTo');
    const isReturnToValid = !!returnToUrl && isValidURL(returnToUrl);
    const isReturnToInValid = !!returnToUrl && !isValidURL(returnToUrl);

    if (isReturnToValid) {
      window.location.replace(returnToUrl);
    }
    if (!returnToUrl || isReturnToInValid) {
      window.location.replace(window.NutmegConfig.LOGIN_HOST);
    }
  }
};
