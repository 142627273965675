import { organisationHeaderTranslations } from '@nm-ui-shared-lib-web/organisation-header/languages/nutmeg';

export default {
  app: {
    common: {
      ...organisationHeaderTranslations,
      appTitle: 'Maintenance - {{global.common.organisation.appTitle}}'
    },
    maintenance: {
      title: '{{global.common.organisation.name}} is temporarily down for maintenance',
      description: 'We are performing scheduled maintenance and should be back online shortly.',
      contact: {
        text: 'Have an urgent question? Contact us at <0>{{global.common.organisation.contact.email}}</0>',
        ariaLabel: 'Contact {{global.common.organisation.name}}',
        supportEmail: '{{global.common.organisation.contact.email}}'
      }
    }
  }
};
