import React from 'react';
import { node } from 'prop-types';
import { OrganisationHeader } from '@nm-ui-shared-lib-web/organisation-header';
import { LayoutCenteredContent } from '@nutkit/layouts';
import { Container } from '@nutkit/component-grid';
import ThemeProvider, { themeNames } from '@nutkit/theme';

const AppContainer = ({ children }) => {
  return (
    <>
      <LayoutCenteredContent
        header={
          <ThemeProvider themeName={themeNames.DARK} noStack>
            <OrganisationHeader translationNameSpace="app.common.header" showHelp noStack />
          </ThemeProvider>
        }
      >
        <Container>{children}</Container>
      </LayoutCenteredContent>
    </>
  );
};

AppContainer.propTypes = {
  children: node.isRequired
};

export default AppContainer;
