import merge from 'lodash.merge';
import Translations, { getCommonTranslations } from '@nm-utils-lib-web/translations';
import { getOrganisation, ORGANISATIONS } from '@nm-utils-lib-web/organisations';

import { nutmeg, johnLewisFinanceGBR } from '../languages';

export default new Translations({
  language: 'en',
  defaultNS: getOrganisation(),
  resources: {
    en: {
      default: {
        ...nutmeg,
        ...getCommonTranslations(ORGANISATIONS.NUTMEG_GBR_BIGBEAR)
      },
      [ORGANISATIONS.JOHN_LEWIS_FINANCE_GBR]: {
        ...merge({}, nutmeg, johnLewisFinanceGBR),
        ...getCommonTranslations(ORGANISATIONS.JOHN_LEWIS_FINANCE_GBR)
      },
      [ORGANISATIONS.NUTMEG_GBR_BIGBEAR]: {
        ...nutmeg,
        ...getCommonTranslations(ORGANISATIONS.NUTMEG_GBR_BIGBEAR)
      }
    }
  }
});
